:root {
  // colors
  --color-carbone: #242830;
  --color-carbone-300: #797979;
  --color-carbone-500: #545454;
  --color-black: #050d12;

  --color-white: #ffffff;
  --color-near-white: #f5f6f6;
  --color-lighter-grey: #eeeeee;
  --color-light-grey: #c6c6c6;
  --color-light-grey-200: #dfe1e5;
  --color-light-grey-300: #a6aaaa;
  --color-medium-grey: #838383;
  --color-dark-grey: #bababa;
  --color-almost-black: #484848;
  --color-near-black: #272727;
  --color-grey-900: #3c3c3b;
  --color-grey-800: #575f6f;
  --color-grey-700: #6a6a6a;
  --color-grey-600: #8c98b0;

  --color-grey-500: #919191;
  --color-grey-400: #a7a7a7;
  --color-grey-300: #c8c8c8;
  --color-grey-200: #e3e3e3;
  --color-grey-100: #e2e7ef;
  --color-blue-500: #41a8f9;
  --color-blue-600: #1294f8;
  --color-blue-700: #0673c6;
  --color-blue-900: #022e4f;
  --color-light-blue: #f1f8ff;
  --color-medium-blue: #546efe;
  --color-light-blue-2: #e7f1fa;
  --color-light-blue-filter: #e8f5fe;

  --color-yellow-500: #fecc00;
  --color-light-yellow: #fdf2d0;
  --color-light-yellow-2: #ffedac;

  --color-red-400: #fd7b62;
  --color-red-500: #fc5130;
  --color-red-600: #e83f3f;

  --color-green-500: #04e762;
  --color-green-900: #09c959;

  --color-warning-900: #ee9207;

  --color-purple-500: #832667;

  --color-personal-token: #f7eaf9;
  --color-external-token: #def7de;
  --color-service-token: #f6f5c5;

  --color-valid-token: #31c752;
  --color-pending-token: #ee9207;
  --color-rejected-token: #eb604d;

  --color-pagination-selected: #f8f9fb;
  // sizes
  --topbar-height: 72px;
  --userbar-height: 68px;
  --tabsbar-height: 48px;
}
